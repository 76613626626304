<template>
  <div class="warp">
    <div class="header">
      <van-nav-bar
          title="课程答疑"
          left-arrow fixed
          @click-left="goBack"
      />
    </div>

    <div class="bodyWarp">
      <div class="curriculumTitle"><span>*</span>问题</div>
      <div class="askContent content">
        <div>{{data.questionContent}}</div>
        <div>
          <van-image v-for="item in data.listPics" :key="item.id" class="imgs" :src="item" @click="preview"/>
        </div>
      </div>
      <div class="curriculumTitle">答疑</div>
      <div class="answerContent content" v-html="data.answerContent"></div>
    </div>

  </div>
</template>

<script>
import { ImagePreview } from 'vant';
export default {
  data() {
    return {
      data: {}
    }
  },
  name: 'curriculumAnswerDetail',
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    preview() {
      ImagePreview(this.data.listPics);
    }
  },
  created() {
    this.data = JSON.parse(window.localStorage.getItem('curriculumAnswerDetail'));
  }
}
</script>

<style lang="less" scoped>
.header {
  font-size: 17px;
  font-weight: 500;
  color: #141414;
  line-height: 24px;
}

/deep/ .van-nav-bar__title {
  font-size: 17px;
  font-weight: 500;
  color: #141414;
}

/deep/ .header .van-icon-arrow-left {
  color: #000;
}

.bodyWarp {
  margin-top: 42px;
  height: calc(100vh - 76px);
  overflow: auto;
  background: #F8F8F8;
  padding: 15px;

  .curriculumTitle{
    height: 22px;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin: 15px 0 15px;
    span{
      color: #E02727;
    }
  }
  .curriculumTitle:first-child{
    margin-top: 0;
  }

  .content{
    background: #fff;
    padding: 15px;
  }

  .imgs{
    width: 62px;
    height: 62px;
    margin: 10px 10px 0 0;
  }
}

</style>
